import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import timescaleImg from "../../../components/database/images/timescale_large.png";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/timescale_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/timescale/";

const Timescale = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant REST APIs on Timescale | Hasura REST API"
      description="Hasura connects to existing Timescale database and gives instant REST CRUD APIs with Authorization."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST API for Timescale"
      desc="Hasura supports Timescale and gives instant REST APIs"
      btnLink="https://cloud.hasura.io/signup?pg=timescale&plcmt=body&cta=try-it-out-in-30-seconds&tech=default"
      btnText="Try it Out in 30s"
      imgSrc={timescaleImg}
      linkUrl="https://hasura.io/docs/latest/graphql/cloud/getting-started/cloud-databases/timescale-cloud.html#cloud-db-timescale-cloud"
      linkText="Check out the docs here"
    />
  </Layout>
);

export default Timescale;
